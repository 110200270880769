import React from 'react';
import Intro from 'src/components/_shared/intro/intro';
import SEO from 'src/components/_shared/seo';

const Index = () => (
    <>
        <SEO title="Варшавская битва 1920 год" lang="ru" description="История важнейшего сражения, о котором вы, вероятно, никогда не слышали." />
        <Intro locale="ru" />
    </>
);
export default Index;
